<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="所属账单流水号" prop="orderNo">
        <a-input v-model="form.orderNo" placeholder="请输入所属账单流水号" />
      </a-form-model-item>
      <a-form-model-item label="所属分类id" prop="recyclingKindId">
        <a-input v-model="form.recyclingKindId" placeholder="请输入所属分类id" />
      </a-form-model-item>
      <a-form-model-item label="所属分类价格" prop="price">
        <a-input v-model="form.price" placeholder="请输入所属分类价格" />
      </a-form-model-item>
      <a-form-model-item label="重量 单位: 千克" prop="weight">
        <a-input v-model="form.weight" placeholder="请输入重量 单位: 千克" />
      </a-form-model-item>
      <a-form-model-item label="总金额 单位:元" prop="totalAmount">
        <a-input v-model="form.totalAmount" placeholder="请输入总金额 单位:元" />
      </a-form-model-item>
      <a-form-model-item label="智能秤ID" prop="intelligentScaleId">
        <a-input v-model="form.intelligentScaleId" placeholder="请输入智能秤ID" />
      </a-form-model-item>
      <a-form-model-item label="称重图片" prop="scaleImage">
        <file-upload v-model="form.scaleImage" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="是否删除 0正常1删除" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button value="">请选择字典生成</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  getItemRecyclingRecord,
  addItemRecyclingRecord,
  updateItemRecyclingRecord
} from '@/api/recyclable/itemRecyclingRecord'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        orderNo: null,
        recyclingKindId: null,
        price: null,
        weight: null,
        totalAmount: null,
        intelligentScaleId: null,
        scaleImage: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        orderNo: [{ required: true, message: '所属账单流水号不能为空', trigger: 'blur' }],
        recyclingKindId: [{ required: true, message: '所属分类id不能为空', trigger: 'blur' }],
        price: [{ required: true, message: '所属分类价格不能为空', trigger: 'blur' }],
        weight: [{ required: true, message: '重量 单位: 千克不能为空', trigger: 'blur' }],
        totalAmount: [{ required: true, message: '总金额 单位:元不能为空', trigger: 'blur' }],
        intelligentScaleId: [{ required: true, message: '智能秤ID不能为空', trigger: 'blur' }],
        scaleImage: [{ required: true, message: '称重图片不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        orderNo: null,
        recyclingKindId: null,
        price: null,
        weight: null,
        totalAmount: null,
        intelligentScaleId: null,
        scaleImage: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getItemRecyclingRecord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateItemRecyclingRecord(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addItemRecyclingRecord(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
