/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-14 11:23:36
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询物品回收记录列表
export function listItemRecyclingRecord (query) {
  return request({
    url: '/internal/recyclable/itemRecyclingRecord/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询物品回收记录详细
export function getItemRecyclingRecord (id) {
  return request({
    url: '/internal/recyclable/itemRecyclingRecord/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增物品回收记录
export function addItemRecyclingRecord (data) {
  return request({
    url: '/internal/recyclable/itemRecyclingRecord',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改物品回收记录
export function updateItemRecyclingRecord (data) {
  return request({
    url: '/internal/recyclable/itemRecyclingRecord',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除物品回收记录
export function delItemRecyclingRecord (id) {
  return request({
    url: '/internal/recyclable/itemRecyclingRecord/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出物品回收记录
export function exportItemRecyclingRecord (query) {
  return request({
    url: '/internal/recyclable/itemRecyclingRecord/export',
    baseURL,
    method: 'get',
    params: query
  })
}
