var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "所属账单流水号", prop: "orderNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入所属账单流水号" },
                model: {
                  value: _vm.form.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属分类id", prop: "recyclingKindId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入所属分类id" },
                model: {
                  value: _vm.form.recyclingKindId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "recyclingKindId", $$v)
                  },
                  expression: "form.recyclingKindId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属分类价格", prop: "price" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入所属分类价格" },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "重量 单位: 千克", prop: "weight" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入重量 单位: 千克" },
                model: {
                  value: _vm.form.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "weight", $$v)
                  },
                  expression: "form.weight",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总金额 单位:元", prop: "totalAmount" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入总金额 单位:元" },
                model: {
                  value: _vm.form.totalAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalAmount", $$v)
                  },
                  expression: "form.totalAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "智能秤ID", prop: "intelligentScaleId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入智能秤ID" },
                model: {
                  value: _vm.form.intelligentScaleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "intelligentScaleId", $$v)
                  },
                  expression: "form.intelligentScaleId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "称重图片", prop: "scaleImage" } },
            [
              _c("file-upload", {
                attrs: { type: "image" },
                model: {
                  value: _vm.form.scaleImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "scaleImage", $$v)
                  },
                  expression: "form.scaleImage",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否删除 0正常1删除", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [
                  _c("a-radio-button", { attrs: { value: "" } }, [
                    _vm._v("请选择字典生成"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }